import React, {FunctionComponent} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import HomeBlock, {HomeBlockProps} from '../components/HomeBlock';
import HeroImage from '../components/HeroImage';
import HtmlHead from '../components/layout/HtmlHead';

// @ts-ignore
import notificationIcon from '../images/notification_icon.png';

const Home: FunctionComponent = () => {
    const {blocks}: { blocks: Array<HomeBlockProps> } = require(`../content/${process.env.THEME}/home-blocks.json`);
    const download: { file: { publicURL: string } } = useStaticQuery(graphql`
        query downloadLink {
            file(name: {eq: "zaken-belangrijk-bij-overstap"}) {
                publicURL
            }
        }
    `);

    return (
        <>
            <HtmlHead/>
            <HeroImage/>
            {
                download.file &&
                <div className='notificationImg mx-auto max-w-screen-2xl flex flex-row px-6 text-black lg:my-8'>
                    <img alt='notification' src={notificationIcon}/>
                    <div>
                        <p>Wanneer in samenspraak met de patiënt en behandelaar wordt besloten over te stappen naar een biosimilar, is
                            zorgvuldigheid geboden.</p>
                        <a download href={download.file.publicURL} style={{textDecoration: 'underline'}}>
                            Hier vindt u een aantal zaken die belangrijk zijn bij een overstap.
                        </a>
                    </div>
                </div>
            }
            <main className='max-w-screen-2xl mx-auto my-2 lg:my-8 stacky-2 lg:flex lg:flex-row lg:stacky-0 lg:stackx-2'>
                {blocks.map((block) => <HomeBlock key={block.title} data={block}/>)}
            </main>
        </>
    );
};

export default Home;

